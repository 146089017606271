import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { Loader, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { View, Flex, Image } from "@aws-amplify/ui-react";
import AWS from "aws-sdk";
import awsexport from "../../aws-exports";
import axios from "axios";
import { token } from "./Layout";
Amplify.configure(awsexport);

const url = process.env.API_URL;
export default function FaceLiveness({ handleImage }) {
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [faceLivenessAnalysis, setFaceLivenessAnalysis] = useState(null);
  const [recognizedPerson, setRecognizedPerson] = useState(null);
  const rekognition = new AWS.Rekognition();
  const dynamodb = new AWS.DynamoDB();

  const getSession = () => {
    setLoading(true);
    axios
      .post("/api/v1/external/gateway/get/session/", {
        token,
      })
      .then(function (response) {
        setSessionId(response?.data?.data?.session_id);
      })
      .catch(function (error) {
        console.error(
          error?.response?.data?.message ?? error?.response?.message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSession();
    // const fetchCreateLiveness = async () => {
    //   const response = await fetch(
    //     "https://3oelohkbbf.execute-api.ap-south-1.amazonaws.com/dev/get/session"
    //   );
    //   const data = await response.json();
    //   setSessionId(data.sessionId);
    //   setLoading(false);
    // };
    // fetchCreateLiveness();
  }, []);

  const handleAnalysisComplete = async () => {
    setLoading(true);
    axios
      .post("/api/v1/external/gateway/get/session/result/", {
        session_id: sessionId,
        token,
      })
      .then(function (response) {
        if (response?.data?.data?.Confidence < 70) {
          throw new Error();
        }
        setFaceLivenessAnalysis(response?.data?.data);
        const byteArray = new Uint8Array(
          response?.data?.data?.ReferenceImage.Bytes.data
        );
        const imageByt = response?.data?.data?.ReferenceImage.Bytes;
        const binaryString = imageByt.data.reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        );
        const base64Image = btoa(binaryString);
        // const blob = new Blob([byteArray], { type: "image/jpeg" });
        // const imageUrl = URL.createObjectURL(blob);
        handleImage(base64Image);
      })
      .catch(function (error) {
        console.error(
          error?.response?.data?.message ?? error?.response?.message
        );
      })
      .finally(() => {
        setLoading(false);
      });

    // const response = await fetch(
    //   "https://3oelohkbbf.execute-api.ap-south-1.amazonaws.com/dev/get/session/results",
    //   {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ sessionId: sessionId }),
    //   }
    // );
    // const data = await response.json();
    // if (data.Confidence < 70) {
    //   throw new Error()
    // }
    // setFaceLivenessAnalysis(data);
    // const byteArray = new Uint8Array(data.ReferenceImage.Bytes.data);
    // const imageByt = data.ReferenceImage.Bytes;
    // const binaryString = imageByt.data.reduce(
    //   (data, byte) => data + String.fromCharCode(byte),
    //   ""
    // );
    // const base64Image = btoa(binaryString);
    // const blob = new Blob([byteArray], { type: "image/jpeg" });
    // // const imageUrl = URL.createObjectURL(blob);
    // handleImage(base64Image);
  };

  return (
    <ThemeProvider>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
      >
        <View
          as="div"
          maxHeight="600px"
          height="600px"
          width="740px"
          maxWidth="740px"
        >
          {loading ? (
            <Loader />
          ) : (
            !(faceLivenessAnalysis && recognizedPerson) && (
              <>
                <FaceLivenessDetector
                  sessionId={sessionId}
                  region="ap-south-1"
                  onAnalysisComplete={handleAnalysisComplete}
                  onUserCancel={() => getSession()}
                />
              </>
            )
          )}
        </View>
      </Flex>
    </ThemeProvider>
  );
}
